<template>
  <v-card class="mt-2 ml-2 mr-2">
    <SalesReportTable ref="refTabSalesReportClick"/>
  </v-card>
</template>

<script>
import SalesReportTable from "../../components/laporan/reports/SalesReport";

export default {
  components: { SalesReportTable },
  data() {
    return {
    }
  },
  methods: {
    tabSalesReportClick(){
      try {
        this.$refs.refTabSalesReportClick.fetchParent()
      }catch (e) {
        e.toString()
      }

    },

  }
}
</script>

<style scoped>
</style>