<template>
  <div class="ma-1">
      <v-hover v-slot="{hover }" open-delay="200">
          <v-card  class="color-gradient-3  rounded-lg" :elevation="hover ? 16 : 2" >
            <v-row class="ma-2">
              <v-col cols="12" sm="4" md="4" v-if="false">

                <div class="mb-4">
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <div class="caption">
                        Total Sales:
                        <span class="blue--text" v-if="!includeConfirmed">After Packing Only</span>
                        <span class="red--text" v-else>All Include Confirmed</span>
                      </div>
                      <div class="subtitle-2">
                        {{ formattedCurrencyValue('Rp.', totalGlobalSales.totalAmountAfterPpn)}}
                      </div>

                      <div class="caption">
                        Paket: <span class="subtitle-2">{{ formattedCurrencyValue('', Math.round(totalGlobalSales.counter))}} </span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row no-gutters>
                    <v-col cols="4" sm="4" md="12">
                      <v-row class="mt-1">
                        <v-col cols="12" sm="12" md="12" class="subtitle-2">
                          On Proses <span class="font-weight-bold">{{totalGlobalSales.terproses}}</span>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="12" sm="12" md="12">
                          <v-progress-circular
                              rotate="360"
                              size="80"
                              width="15"
                              color="teal"
                              :value="Math.round(totalGlobalSales.terproses/totalGlobalSales.counter*100) "
                          >
                            {{  Math.round(totalGlobalSales.terproses/totalGlobalSales.counter*100)  }}<span class="caption">%</span>
                          </v-progress-circular>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="4" sm="4" md="12">
                      <v-row class="mt-1">
                        <v-col cols="12" sm="12" md="12" class="subtitle-2">
                          Delivery <span class="font-weight-bold">{{totalGlobalSales.dalamPengiriman}}</span>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="12" sm="12" md="12">
                          <v-progress-circular
                              rotate="360"
                              size="80"
                              width="15"
                              color="teal"
                              :value="Math.round(totalGlobalSales.dalamPengiriman/totalGlobalSales.counter*100) "
                          >
                            {{  Math.round(totalGlobalSales.dalamPengiriman/totalGlobalSales.counter*100)  }}<span class="caption">%</span>
                          </v-progress-circular>
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="4" sm="4" md="12">
                      <v-row class="mt-1">
                        <v-col cols="12" sm="12" md="12" class="subtitle-2">
                          Terkirim <span class="font-weight-bold">{{totalGlobalSales.terkirim}}</span>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="12" sm="12" md="12">
                          <v-progress-circular
                              rotate="360"
                              size="80"
                              width="15"
                              color="blue"
                              :value="Math.round(totalGlobalSales.terkirim/totalGlobalSales.counter*100) "
                          >
                            {{  Math.round(totalGlobalSales.terkirim/totalGlobalSales.counter*100)  }}<span class="caption">%</span>
                          </v-progress-circular>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>



                  <v-row no-gutters>
                    <v-col cols="4" sm="4" md="12">
                      <v-row class="mt-1">
                        <v-col cols="12" sm="12" md="12" class="subtitle-2 red--text">
                          Proses Retur <span class="font-weight-bold">{{Math.round(totalGlobalSales.retur)}}</span>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="12" sm="12" md="12">
                          <v-progress-circular
                              rotate="360"
                              size="80"
                              width="15"
                              color="red"
                              :value="Math.round(totalGlobalSales.retur/totalGlobalSales.counter*100) "
                          >
                            {{  Math.round(totalGlobalSales.retur/totalGlobalSales.counter*100)  }}<span class="caption">%</span>
                          </v-progress-circular>
                        </v-col>
                      </v-row>

                    </v-col>
                    <v-col cols="4" sm="4" md="12">
                      <v-row class="mt-1">
                        <v-col cols="12" sm="12" md="12" class="subtitle-2 red--text">
                          Retur Complete <span class="font-weight-bold">{{totalGlobalSales.returSampaiGudang}}</span>
                        </v-col>
                      </v-row>
                        <v-row no-gutters>
                          <v-col cols="12" sm="12" md="12">
                            <v-progress-circular
                                rotate="360"
                                size="80"
                                width="15"
                                color="blue"
                                :value="Math.round(totalGlobalSales.returSampaiGudang/totalGlobalSales.retur*100) "
                            >
                              {{  Math.round(totalGlobalSales.returSampaiGudang/totalGlobalSales.retur*100)  }}<span class="caption">%</span>
                            </v-progress-circular>
                          </v-col>
                        </v-row>

                    </v-col>
                  </v-row>

                </div>

              </v-col>

              <v-col cols="12" sm="12" md="8">
                <v-flex class="d-flex align-baseline">
                  <div class="font-weight-bold subtitle-1">SALES</div>
                  <div class="ml-8">
                    <v-menu
                        v-model="menuDate1"
                        :close-on-content-click="false"
                        max-width="290"
                    >
                      <template v-slot:activator="{ on, attrs } ">
                        <v-text-field
                            :value="computedDateFormattedDatefns(trDate)"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            dense
                        ></v-text-field>
                      </template>

                      <v-date-picker
                          v-model="trDate"
                          @change="menuDate1 = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                  <v-switch
                      v-model="includeConfirmed"
                      class="ml-8"
                      :label="includeConfirmed? 'Include Confirmed': 'Exclude'"
                      dense
                  ></v-switch>
                </v-flex>

                <v-flex>
                  <div v-for="(item, i) in itemsFSalesmanWithSales" :key="i" >
                    <div v-if="item.salesByFmaterialOfSalesman.length>0">
                      <v-divider class="mb-2"></v-divider>
                      <v-row>
                        <v-col cols="12" sm="3" md="3">

                          <v-flex class="justify-lg-space-around">
                            <v-flex>
                              <v-avatar>
                                <img
                                    :lazy-src="lookupImageUrlLazy(item)"
                                    :src="lookupImageUrl(item)"
                                    alt="John"
                                >
                              </v-avatar>
                            </v-flex>
                            <div v-if="item.phone>821999">
                              <v-btn
                                  x-small
                                  class="no-gutters"
                                  plain :href="`https://api.whatsapp.com/send?phone=+62${item.phone}&text=Hi kak`" target="_blank"
                              >
                                <v-icon x-small color="green" >mdi-whatsapp</v-icon>
                                <span class="caption">
                                      {{ item.spname }}
                                    </span>
                              </v-btn>
                            </div>
                            <div v-else>
                              <v-icon x-small color="grey" >mdi-whatsapp</v-icon>
                              <span class="caption">
                                    {{ item.spname }}
                                  </span>
                            </div>
                          </v-flex>

                        </v-col>

                        <v-col cols="12" sm="8" md="8">
                          <v-row no-gutters class="subtitle-2" v-for="(salesItem, x) in item.salesByFmaterialOfSalesman" :key="x">
                            <v-col cols="5" sm="5" md="5">{{ salesItem.pcode }}</v-col>
                            <v-col cols="3" sm="3" md="3" class="d-flex justify-end"> {{ salesItem.totalQty }}</v-col>
                            <v-col cols="4" sm="4" md="4" class="d-flex justify-end">
                              {{ formattedCurrencyValue('', salesItem.totalSprice2) }}
                            </v-col>
                          </v-row>
                          <v-row no-gutters class="mb-1">
                            <v-col cols="12" sm="8" md="8">
                              <div class="caption d-flex justify-start">
                                dari <span class="font-weight-bold ml-1 mr-1">{{ item.counter }}</span> transaksi <span>{{ formattedCurrencyValue("Rp ", item.totalAmountAfterPpn) }}</span>
                              </div>
                            </v-col>
                            <v-col cols="12" sm="4" md="4">
                              <div class="caption grey--text font-weight-light d-flex justify-end ">
                                *{{ lookupFDivision(item.fdivisionBean).description }}
                                |
                                {{item.salesType}}
                              </div>
                            </v-col>

                          </v-row>
                        </v-col>

                      </v-row>
                    </div>
                  </div>

                </v-flex>
              </v-col>

            </v-row>
          </v-card>
        </v-hover>
  </div>
</template>

<script>
import {format, parseISO} from "date-fns";
import FtSaleshFilter from "@/models/payload/ft-salesh-filter";
import FtSaleshService from "@/services/apiservices/ft-salesh-service";
import ERole from "@/models/e-role";
import EStatusPengiriman, { EStatusPengirimans} from "@/models/e-status-pengiriman";
import FileService from "@/services/apiservices/file-service";

export default {
  name: "DashboardSales",
  data(){
    return {
      includeConfirmed: true,
      // trDate: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
      menuDate1: false,
      // trDate: '',
      trDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      // trDate: function(d){ d.setDate(d.getDate()-1); return d}(new Date).toISOString().substring(0, 10),
      // trDate:
      //     function(d){
      //       d.setDate(d.getDate()-1);
      //       return d
      //     }(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      // ftSaleshs: [],

      itemsEStatusPengiriman: EStatusPengirimans,
      totalSales: 0,
    }
  },
  watch:{
    trDate: function (){
      this.fetchFtSalesh()
    }
  },
  computed:{
    currentUser(){
      return this.$store.state.auth.user;
    },
    ftSaleshFiltered(){
      let itemsFtSalesh = []
      if (this.includeConfirmed){
        itemsFtSalesh = this.$store.state.salesReport.itemsFtSalesh
      }else {
        itemsFtSalesh = this.$store.state.salesReport.itemsFtSalesh.filter(x => x.statusPengiriman != EStatusPengiriman.CONFIRMED)
      }
      return itemsFtSalesh
    },

    itemsFSalesman(){
      const listFSalesman = []

      if (this.currentUser.salesmanOf > 0 &&  [ERole.ROLE_CS].some(x =>this.currentUser.roles.includes(x)) ) {
        this.$store.state.data.listFSalesman.filter(x => x.id === this.currentUser.salesmanOf).forEach(item => {
          listFSalesman.push(item)
        })
      }else {
        this.$store.state.data.listFSalesman.forEach( item =>{
          listFSalesman.push(item)
        })
      }

      // console.log(JSON.stringify(listFSalesman))

      return listFSalesman
    },

    itemsFSalesmanWithSales() {
      const listFSalesman = []

      if (this.currentUser.salesmanOf > 0 &&  [ERole.ROLE_CS].some(x =>this.currentUser.roles.includes(x)) ) {
        this.$store.state.data.listFSalesman.filter(x => x.id  === this.currentUser.salesmanOf).forEach( item =>{
          let newItem = item
          item.salesByFmaterialOfSalesman = this.salesByFmaterialOfSalesman(item.id)
          item.counter = this.totalSalesByFtSalesh(item.id).counter
          item.totalAmountAfterPpn = this.totalSalesByFtSalesh(item.id).totalAmountAfterPpn

          listFSalesman.push(newItem)

        })
      }else {
        this.$store.state.data.listFSalesman.forEach( item =>{
          let newItem = item
          item.salesByFmaterialOfSalesman = this.salesByFmaterialOfSalesman(item.id)
          item.counter = this.totalSalesByFtSalesh(item.id).counter
          item.totalAmountAfterPpn = this.totalSalesByFtSalesh(item.id).totalAmountAfterPpn

          listFSalesman.push(newItem)

        })
      }

      // console.log(JSON.stringify(listFSalesman))

      return listFSalesman.sort((a, b) =>(a.totalAmountAfterPpn > b.totalAmountAfterPpn? -1 : 1 ))
    },

    totalGlobalSales(){
      let counter = 0.001
      let terproses = 0
      let dalamPengiriman = 0
      let terkirim = 0
      let retur = 0.001
      let returSampaiGudang = 0

      let totalAmountAfterPpn = 0.0
      this.ftSaleshFiltered.forEach(item => {
        // console.log(item.statusPengiriman)

        counter +=1
        totalAmountAfterPpn += item.amountAfterDiscPlusRpAfterPpn_FG
        if (item.statusPengiriman >EStatusPengiriman.CONFIRMED){
          terproses +=1
        }
        if (item.statusPengiriman >EStatusPengiriman.WAIT_PICKUP){
          dalamPengiriman +=1
        }
        if (item.statusPengiriman == EStatusPengiriman.PAID || item.statusPengiriman==EStatusPengiriman.RECEIVED){
          terkirim +=1
        }
        if (item.statusPengiriman == EStatusPengiriman.CANCELED || item.statusPengiriman==EStatusPengiriman.COMPLAINED ||item.statusPengiriman==EStatusPengiriman.RETURN_PROCESS  ){
          retur +=1
        }
        if (item.statusPengiriman==EStatusPengiriman.RETURN_LOCKED){
          returSampaiGudang +=1
        }

      })

      const newItem = {counter: counter, terproses: terproses, dalamPengiriman: dalamPengiriman,
        terkirim: terkirim, retur: retur, returSampaiGudang: returSampaiGudang,
        totalAmountAfterPpn: totalAmountAfterPpn}

      return newItem
    },

    itemsFMaterial(){
      return this.$store.state.data.listFMaterial
    },

    itemsFExpedisi(){
      return this.$store.state.data.listFExpedisi
    },
    itemsFStore(){
      return this.$store.state.data.listFStore
    },
    itemsFWarehouse(){
      return this.$store.state.data.listFWarehouse
    },

    itemsFDivision(){
      return this.$store.state.data.listFDivision
    },

  },
  methods:{


    fetchFtSalesh(){
      this.runExtendedFilter()
    },

    salesByFmaterialOfSalesman(fsalesmanBean){
      // console.log(JSON.stringify(this.ftSaleshFiltered))

      const listFtSalesdItems = []
      const listFMaterial = []
      let salesByMaterial = []

      this.ftSaleshFiltered.filter(x => x.fsalesmanBean == fsalesmanBean).forEach(item => {
        item.listFtSalesdItems.forEach(itemDetil => {
          listFMaterial.push(itemDetil.fmaterialBean)
          listFtSalesdItems.push(itemDetil)
        })
      })

      let fmaterialSet = new Set(listFMaterial)

      fmaterialSet.forEach(fmaterialBean => {
        const newSales = {pcode: '', totalQty: 0, totalSprice2: 0.0}
        newSales.pcode = this.lookupFMaterial(fmaterialBean).pcode
        listFtSalesdItems.filter( x=> x.fmaterialBean==fmaterialBean).forEach(item => {
          newSales.totalQty += item.qty
          newSales.totalSprice2 += (item.qty * item.sprice2)
        })

        if (newSales.totalQty>0){
          salesByMaterial.push(newSales)
        }

      })


      return salesByMaterial
    },
    totalSalesByFtSalesh(fsalesmanBean){
      let counter = 0
      let totalAmountAfterPpn = 0.0
      this.ftSaleshFiltered.filter(x => x.fsalesmanBean == fsalesmanBean).forEach(item => {
        counter +=1
        totalAmountAfterPpn += item.amountAfterDiscPlusRpAfterPpn_FG
      })

      const newItem = {counter: counter, totalAmountAfterPpn: totalAmountAfterPpn}

      return newItem
    },

    lookupFMaterial (fmaterialBean) {
      const str = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    runExtendedFilter(){

      const ftSaleshFilter = new FtSaleshFilter()

      // ftSaleshFilter.fsalesmanIds = [294]
      // ftSaleshFilter.fsalesmanIds = this.itemsFSalesman.map( item => item.id)

      // if (this.filterFsalesmans.length >0){
      //   ftSaleshFilter.fsalesmanIds = this.filterFsalesmans
      // }else {
        ftSaleshFilter.fsalesmanIds = this.itemsFSalesman.map( item => item.id)
      // }


      ftSaleshFilter.fstoreIds = this.itemsFStore.map( item => item.id)
      ftSaleshFilter.fwarehouseIds = this.itemsFWarehouse.map( item => item.id)
      ftSaleshFilter.fexpedisiIds =  this.itemsFExpedisi.map( item => item.id)
      // ftSaleshFilter.statusPengirimanIds = this.itemsEStatusPengiriman.map( item => item.id)

      ftSaleshFilter.statusPengirimanIds = [
        EStatusPengiriman.CONFIRMED,
        EStatusPengiriman.WAIT_PICKUP,
        EStatusPengiriman.ON_PACKING, EStatusPengiriman.WAIT_PICKUP, EStatusPengiriman.PICKED_UP,
        EStatusPengiriman.ON_DELIVERY, EStatusPengiriman.RECEIVED, EStatusPengiriman.PAID,
        EStatusPengiriman.COMPLAINED
      ]

      ftSaleshFilter.fmaterialIds = this.itemsFMaterial.map(item => item.id)

      if (this.trDate == ''){
        this.trDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
      }
      ftSaleshFilter.orderDateFrom =  this.trDate
      ftSaleshFilter.orderDateTo =  this.trDate

      ftSaleshFilter.pageNo = 1
      ftSaleshFilter.pageSize = 99999
      ftSaleshFilter.sortBy = "id"
      ftSaleshFilter.order = "DESC"
      ftSaleshFilter.search = ""

      // console.log(JSON.stringify(ftSaleshFilter))

      FtSaleshService.getAllFtSaleshContainingExt(ftSaleshFilter).then(
          response =>{
            const { items} = response.data
            // this.ftSaleshs = items
            this.$store.dispatch('salesReport/reloadItemsFtSalesh', items)

            // console.log(JSON.stringify(items))
          },
          error =>{
            console.log(error)
          }
      )

    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return str[0]
      }else {
        return {id: 0, description: ''}
      }
    },

    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      return prefix + "" + parseFloat(value).toLocaleString()
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/account_icon.png'
      }else {
        return FileService.image_url_low(item.avatarImage)
      }
    },

    lookupImageUrlLazy(){
      return './assets/images/account_icon.png'
    },

  },
  mounted() {
    // setTimeout(() => this.runExtendedFilter(), 2000);
    setTimeout(this.runExtendedFilter, 150);

    // setTimeout(function(){
    //   // this.runExtendedFilter()
    //   alert("After 5 seconds!");
    //   this.trDate= (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    // }, 5000);

  }
}
</script>


<style scoped>

</style>